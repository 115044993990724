export const loadData = (params, search) => dispatch => {
  return dispatch(
    Promise.all(
      setTimeout(() => {
        console.log('foo');
      }, 100)
    ).then(data => {
      return { a: 10, b: 20 };
    })
  );
};
